<template>
    <window-portal :open="dataProps.show" >
        <div style="font-family: arial !important; margin-top: 10px; margin-bottom: 10px;">
            <center><div><p style="font-size: 30px;">Vista previa de impresión Reporte de Compras</p></div></center>
            <br/>
            <center>
                <button class="btnclean" style="font-size: 18px;" @click="Print()">
                    Imprimir
                </button>
                &nbsp;&nbsp;
                <button class="btnclean" style="font-size: 18px;" @click="Download()">
                    Descargar
                </button>
                
               
            </center>
            <center><div v-if="loading"><p>Cargando...</p></div></center>

        </div>
        <div style="font-family: arial !important; margin-top: 10px; margin-bottom: 10px;">
            <center v-if="mailLabel">
                {{ mailLabel }}
            </center>
        </div>

        <div class="book" style="margin: 0; padding: 0; background-color: rgb(105, 105, 105); font-family: arial !important; padding-top: 20px; padding-bottom: 20px;">
                <div style="margin: 0 auto; width: 1293px;">
                    <div ref="dataprint" style="margin: 0 !important; padding: 0 !important;">
                        <!--div style="position: relative; width: 1000px; min-height: 1294px; background: white;"-->
                        <div v-for="(page, indexPage) in pages" :key="indexPage" style="position: relative; width: 1293px; min-height: 900px; background: white;">
                            <hr/>
                            <div class="subpage" style="height: 900px; padding-top: 20px; overflow: hidden;">
                                <div style="margin-left: 80px; margin-right: 50px;">
                                    <div style="text-align: center; font-size: 12px;">
                                                        <h3>LIBRO DE REGISTRO DE Compras</h3>
                                                                                                
                                                    <p>Mes: {{dataProps.header.month + ' ' +dataProps.header.year  }}</p>
                                    </div>
                                    <br/>
                                    <!-- Heres is teh body -->
                                    <div style="width: 100%;">
                                        <table style="width: 100% !important; font-size: 6px; border-collapse: collapse; border: 1px solid black;">
                                            <tr style="background: gray; ">
                                                <td colspan="21" style="text-align: center; color: white;background: gray;">CUERPO DEL DOCUMENTO</td>
                                                
                                            </tr>
                                            
                                            <tr>
                                                <td style="border: 1px solid;text-align: end; padding-left: 2px;">FECHA DE EMISIÓN</td>
                                                <td style="border: 1px solid;text-align: end; padding-left: 2px;">CLASE DE DOCUMENTO</td>
                                                <td style="border: 1px solid;text-align: end; padding-left: 2px;">TIPO DE DOCUMENTO</td>
                                                <td style="border: 1px solid;text-align: end; padding-left: 2px;">NÚMERO DE DOCUMENTO</td>
                                                <td style="border: 1px solid;text-align: end; padding-left: 2px;">NIT O NRC DEL PROVEEDOR</td>
                                                <td style="border: 1px solid;text-align: end; padding-left: 2px;">NOMBRE DEL PROVEEDOR</td>
                                                <td style="border: 1px solid;text-align: end; padding-left: 2px;">COMPRAS INTERNAS EXENTAS Y/O NO SUJETAS</td>
                                                <td style="border: 1px solid;text-align: end; padding-left: 2px;">INTERNACIONES EXENTAS Y/O NO SUJETAS</td>
                                                <td style="border: 1px solid;text-align: end; padding-left: 2px;">IMPORTACIONES EXENTAS Y/O NO SUJETAS</td>
                                                <td style="border: 1px solid;text-align: end; padding-left: 2px;">COMPRAS INTERNAS GRAVADAS</td>
                                                <td style="border: 1px solid;text-align: end; padding-left: 2px;">INTERNACIONES GRAVADAS DE BIENES</td>
                                                <td style="border: 1px solid;text-align: end; padding-left: 2px;">IMPORTACIONES GRAVADAS DE BIENES</td>
                                                <td style="border: 1px solid;text-align: end; padding-left: 2px;">IMPORTACIONES GRAVADAS DE SERVICIOS</td>
                                                <td style="border: 1px solid;text-align: end; padding-left: 2px;">CRÉDITO FISCAL</td>
                                                <td style="border: 1px solid;text-align: end; padding-left: 2px;">TOTAL DE COMPRAS</td>
                                                <td style="border: 1px solid;text-align: end; padding-left: 2px;">DUI DEL PROVEEDOR</td>
                                                <td style="border: 1px solid;text-align: end; padding-left: 2px;">TIPO DE OPERACIÓN</td>
                                                <td style="border: 1px solid;text-align: end; padding-left: 2px;">CLASIFICACIÓN</td>
                                                <td style="border: 1px solid;text-align: end; padding-left: 2px;">SECTOR</td>
                                                <td style="border: 1px solid;text-align: end; padding-left: 2px;">TIPO DE COSTO / GASTO</td>
                                                <td style="border: 1px solid;text-align: end; padding-left: 2px;">NÚMERO DE ANEXO</td>
                                            </tr>
                                            <!--tr v-for="(item, index) in dataProps.data.body" :key="index"-->
                                            <tr v-for="(item, index) in valuesRows[indexPage]" :key="index">
                                                <td style="border-left: 1px solid; border-right: 1px solid;">{{item["FECHA DE EMISIÓN"]}}</td>
                                                <td style="border-left: 1px solid; border-right: 1px solid;">{{item["CLASE DE DOCUMENTO"]}}</td>
                                                <td style="border-left: 1px solid; border-right: 1px solid;">{{item["TIPO DE DOCUMENTO"]}}</td>
                                                <td style="border-left: 1px solid; border-right: 1px solid;">{{item["NÚMERO DE DOCUMENTO"]}}</td>
                                                <td style="border-left: 1px solid; border-right: 1px solid;">{{item["NIT O NRC DEL PROVEEDOR"]}}</td>
                                                <td style="border-left: 1px solid; border-right: 1px solid;">{{item["NOMBRE DEL PROVEEDOR"]}}</td>
                                                <td style="border-left: 1px solid; border-right: 1px solid; text-align: end">{{numberWithCommas(item["COMPRAS INTERNAS EXENTAS Y/O NO SUJETAS"])}}</td>
                                                <td style="border-left: 1px solid; border-right: 1px solid; text-align: end">{{numberWithCommas(item["INTERNACIONES EXENTAS Y/O NO SUJETAS"])}}</td>
                                                <td style="border-left: 1px solid; border-right: 1px solid; text-align: end">{{numberWithCommas(item["IMPORTACIONES EXENTAS Y/O NO SUJETAS"])}}</td>
                                                <td style="border-left: 1px solid; border-right: 1px solid; text-align: end">{{numberWithCommas(item["COMPRAS INTERNAS GRAVADAS"])}}</td>
                                                <td style="border-left: 1px solid; border-right: 1px solid; text-align: end">{{numberWithCommas(item["INTERNACIONES GRAVADAS DE BIENES"])}}</td>
                                                <td style="border-left: 1px solid; border-right: 1px solid; text-align: end">{{numberWithCommas(item["IMPORTACIONES GRAVADAS DE BIENES"])}}</td>
                                                <td style="border-left: 1px solid; border-right: 1px solid; text-align: end">{{numberWithCommas(item["IMPORTACIONES GRAVADAS DE SERVICIOS"])}}</td>
                                                <td style="border-left: 1px solid; border-right: 1px solid; text-align: end">{{numberWithCommas(item["CRÉDITO FISCAL"])}}</td>
                                                <td style="border-left: 1px solid; border-right: 1px solid; text-align: end">{{numberWithCommas(item["TOTAL DE COMPRAS"])}}</td>
                                                <td style="border-left: 1px solid; border-right: 1px solid;">{{item["DUI DEL PROVEEDOR"]}}</td>
                                                <td style="border-left: 1px solid; border-right: 1px solid;">{{item["TIPO DE OPERACIÓN"]}}</td>
                                                <td style="border-left: 1px solid; border-right: 1px solid;">{{item["CLASIFICACIÓN"]}}</td>
                                                <td style="border-left: 1px solid; border-right: 1px solid;">{{item["SECTOR"]}}</td>
                                                <td style="border-left: 1px solid; border-right: 1px solid;">{{item["TIPO DE COSTO / GASTO"]}}</td>
                                                <td style="border-left: 1px solid; border-right: 1px solid;">{{item["NÚMERO DE ANEXO"]}}</td>
                                            </tr>

                                            

                                        </table>
                                    </div>
                                    <template v-if="numPages == (indexPage+1)">
                                            <div style="display: inline-block; width: 100%; vertical-align: top;">
                                                
                                               
                                                <table  style="width: 100%; margin-top: 1rem; font-size: 12px; border-collapse: collapse; border: 1px solid black;">
                                                    <thead>
                                                        <tr style="background: gray; ">
                                                            <td colspan="6" style="text-align: center; color: white;background: gray;">RESUMEN DE COMPRAS</td>
                                                        </tr>
                                                    </thead>
                                                
                                                    <tbody>
                                                        <tr v-for="(value, name, index) in consolidateValues" :key="name">
                                                            

                                                            <td colspan="3" style="text-align: end;" :style="index === borderTp ? 'border-top: 1px solid;': '' " >{{ value.label + ':' }}</td>
                                                            <td  colspan="3" :style="`${index === borderTp ? 'border-top: 1px solid; ': '' } text-align: end;`"  >{{ value.value }}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                          
                                            </div>
                                        </template>
                                </div>
                                
                                <div style="position: absolute; bottom: 0; width: 100%; left: 80px">
                                    <p style="font-size:10px;">Pagina {{ indexPage+1 }} de {{ numPages }}</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
        </div>
    </window-portal>
</template>

<script>

import jsPDF from "jspdf"
//import QRCode from 'qrcode';
//import html2canvas from "html2canvas"
import WindowPortal from "@/components/ReportsAndPrintings/WindowPortal.vue"
import { addFormatToNIT, addFormatNRC } from "../../helpers/misc"
import { numberWithCommas } from "../../helpers/money";
import {numberToText} from '@/helpers/conveterNumberText.js'

export default {
    name: "PrintReportsPurchase",
    props: ['dataProps'],
    components: {WindowPortal},
    data() {
        return {
            header: null,
            footer: null,
            loading: false,
            //seal: "",
            //sealBranch: "",
            numPages: 0,
            //valuesRows: []
            mailLabel: null,
            valuesRows: [],
            urlQr: null,
            typePrint: 0,
            consolidateValues: {},
            borderTp: 0,
            Currency: JSON.parse(localStorage.getItem('branch')).Currency
        }
    },
    mounted() {
        this.calculatePagesOfDoc()
        //console.log(this.dataProps);
        //console.log(this.dataProps.data.branch)
        //console.log(this.dataProps.data.dteJson)
        //this.seal = JSON.parse(localStorage.getItem("user")).seal || ""
        //this.sealBranch = JSON.parse(localStorage.getItem("user")).sealBranch || ""
        this.getHeaderFooter()
        // if(this.dataProps.)
        // this.$nextTick(() => {
        //     this.sendMail();
        // })
    },
    computed: {
        /*edad() {
            let edad = this.dataProps.data.profile.Age
            if (edad == null || edad == undefined) {
                return ''
            } else {
                return edad
            }
        },*/
        BillingNrc() {
            return addFormatNRC(this.dataProps.data.branch.BusinessBillingNRC)
        },
        BillingNit() {
            //console.log(this.dataProps.data.branch.BusinessBillingNIT);
            return addFormatToNIT(this.dataProps.data.branch.BusinessBillingNIT)
        },
        pages() {
            // Generar un arreglo con `numPages` elementos
            return Array.from({ length: this.numPages });
        },
    },
    watch: {
        'dataProps.show'() {
            if(this.dataProps.show){
                setTimeout(async () => {
                    await this.calculatePagesOfDoc()
                    //console.log('header ', this.dataProps.data.header);
                   
                    // const partes = this.dataProps.data.header.InvoiceDate.split("/");
                    //const dia = partes[0];
                   // const mes = partes[1];
                    //const anio = partes[2];

                    // Crear una nueva fecha en formato "yyyy-mm-dd"
                   // const fechaNueva = `${anio}-${mes}-${dia}`;
                   //const fechaNueva = '2021-01-01'
                   // const url = this.dataProps.data.branch.AuthFeMode == 1 ? `https://admin.factura.gob.sv/consultaPublica?ambiente=01&codGen=${this.dataProps.data.header.CodigoGeneracion}&fechaEmi=${fechaNueva}` : `https://admin.factura.gob.sv/consultaPublica?ambiente=00&codGen=${this.dataProps.data.header.CodigoGeneracion}&fechaEmi=${fechaNueva}`;
                    
                    // Generar el código QR y mostrarlo en el elemento con id "qrcode"

                    /* const opt = {
                        errorCorrectionLevel: 'H',
                        type: 'image/png',
                        quality: 0.3,
                        margin: 1,
                        color: {
                            dark:"#000000",
                            light:"#ffffff"
                        },
                        width: 100
                    } */

                    // QRCode.toCanvas(this.$refs.qrcode, url, { width: 100 }, (error) => {
                    //     if (error) {
                    //         console.log('Error al generar el código QR:', error);
                    //     }
                    // });
                    
                    /* QRCode.toDataURL(url, opt, (error, url) => {
                        if (error) {
                            console.log('Error al generar el código QR:', error);
                            throw error;
                        }

                        //console.log(url);
                        this.urlQr = url
                        //this.$refs.qrcode.src = url;
                    }); */
                }, 0);

                // this.$nextTick(() => {
                //     this.sendMail();
                // });
                // if(this.dataProps.fromCreateInvoice) {
                // }
            }
        },
        'dataProps'() {
            console.log('dataProps');
            this.calculatePagesOfDoc()
        }
    },
    methods: {
        GetnumberToText(number) {
            if(number) return numberToText(number)
            else return ''
        },
        numberWithCommas(x) {
            return `${this.Currency}${numberWithCommas(x)}`;
        },
        getHeaderFooter() {
            let search = {};
            search.ID_business = JSON.parse(localStorage.getItem("user")).businessid || ""
            search.ID_branch = JSON.parse(localStorage.getItem("user")).branch || ""
            this.$API.HF.getHF(search).then((data) => {
                let header = [], footer = [];
                header = data.filter((x) => x.State == '1' && x.Type == 'Header');
                this.header = header.length > 0 ? JSON.parse(header[0].Body).html : '';
                this.header = this.header.replace(/(\n)/gm, "");
                footer = data.filter((x) => x.State == '1' && x.Type == 'Footer');
                this.footer = footer.length > 0 ? JSON.parse(footer[0].Body).html : '';
            })
        },
        calculateConsolidatedPurchase(){
            this.borderTp = 8
            // "FECHA DE EMISIÓN",
            // "CLASE DE DOCUMENTO",
            // "TIPO DE DOCUMENTO",
            // "NÚMERO DE DOCUMENTO",
            // "NIT O NRC DEL PROVEEDOR",
            // "NOMBRE DEL PROVEEDOR",
            // "COMPRAS INTERNAS EXENTAS Y/O NO SUJETAS",
            // "INTERNACIONES EXENTAS Y/O NO SUJETAS",
            // "IMPORTACIONES EXENTAS Y/O NO SUJETAS",
            // "COMPRAS INTERNAS GRAVADAS",
            // "INTERNACIONES GRAVADAS DE BIENES",
            // "IMPORTACIONES GRAVADAS DE BIENES",
            // "IMPORTACIONES GRAVADAS DE SERVICIOS",
            // "CRÉDITO FISCAL",
            // "TOTAL DE COMPRAS",
            // "DUI DEL PROVEEDOR",
            // "TIPO DE OPERACIÓN",
            // "CLASIFICACIÓN",
            // "SECTOR",
            // "TIPO DE COSTO / GASTO",
            // "NÚMERO DE ANEXO"
            this.consolidateValues = {
                "COMPRAS INTERNAS EXENTAS Y/O NO SUJETAS": {
                    label: "COMPRAS INTERNAS EXENTAS Y/O NO SUJETAS",
                    value: 0
                },
                "INTERNACIONES EXENTAS Y/O NO SUJETAS": {
                    label: "INTERNACIONES EXENTAS Y/O NO SUJETAS",
                    value: 0
                },
                "IMPORTACIONES EXENTAS Y/O NO SUJETAS": {
                    label: "IMPORTACIONES EXENTAS Y/O NO SUJETAS",
                    value: 0
                },
                "COMPRAS INTERNAS GRAVADAS": {
                    label: "COMPRAS INTERNAS GRAVADAS",
                    value: 0
                },
                "INTERNACIONES GRAVADAS DE BIENES": {
                    label: "INTERNACIONES GRAVADAS DE BIENES",
                    value: 0
                },
                "IMPORTACIONES GRAVADAS DE BIENES": {
                    label: "IMPORTACIONES GRAVADAS DE BIENES",
                    value: 0
                },
                "IMPORTACIONES GRAVADAS DE SERVICIOS": {
                    label: "IMPORTACIONES GRAVADAS DE SERVICIOS",
                    value: 0
                },
                "CRÉDITO FISCAL": {
                    label: "CRÉDITO FISCAL",
                    value: 0
                },
                "TOTAL DE COMPRAS": {
                    label: "TOTAL DE COMPRAS",
                    value: 0
                },
            }
            this.dataProps.data.body.forEach(element => {
                this.consolidateValues["COMPRAS INTERNAS EXENTAS Y/O NO SUJETAS"].value += parseFloat(element["COMPRAS INTERNAS EXENTAS Y/O NO SUJETAS"]);
                this.consolidateValues["INTERNACIONES EXENTAS Y/O NO SUJETAS"].value += parseFloat(element["INTERNACIONES EXENTAS Y/O NO SUJETAS"]);
                this.consolidateValues["IMPORTACIONES EXENTAS Y/O NO SUJETAS"].value += parseFloat(element["IMPORTACIONES EXENTAS Y/O NO SUJETAS"]);
                this.consolidateValues["COMPRAS INTERNAS GRAVADAS"].value += parseFloat(element["COMPRAS INTERNAS GRAVADAS"]);
                this.consolidateValues["INTERNACIONES GRAVADAS DE BIENES"].value += parseFloat(element["INTERNACIONES GRAVADAS DE BIENES"]);
                this.consolidateValues["IMPORTACIONES GRAVADAS DE BIENES"].value += parseFloat(element["IMPORTACIONES GRAVADAS DE BIENES"]);
                this.consolidateValues["IMPORTACIONES GRAVADAS DE SERVICIOS"].value += parseFloat(element["IMPORTACIONES GRAVADAS DE SERVICIOS"]);
                this.consolidateValues["CRÉDITO FISCAL"].value += parseFloat(element["CRÉDITO FISCAL"]);
                this.consolidateValues["TOTAL DE COMPRAS"].value += parseFloat(element["TOTAL DE COMPRAS"]);
            
            });

            for (const key in this.consolidateValues) {
                this.consolidateValues[key].value = `${this.numberWithCommas( this.consolidateValues[key].value )}`;
            }
        },
        calculatePagesOfDoc() {
            let pages = 0
            let rows = 0
            this.valuesRows[pages] = []

            try{
                
                    this.calculateConsolidatedPurchase();
                    this.typePrint = 0
                    //Cuando es solo una página
                    if(this.dataProps.data.body.length <= 40){
                        for(let i = 0; i < this.dataProps.data.body.length; i++){
                            rows++
                            this.valuesRows[pages].push(this.dataProps.data.body[i])
                        }    
                    }
                    else{
                        for(let i = 0; i < this.dataProps.data.body.length; i++){
                            if(rows < 40 && (i !== this.dataProps.data.body.length - 1)){
                                rows++
                                this.valuesRows[pages].push(this.dataProps.data.body[i])
                            }
                            else if(rows <= 20){
                                rows++
                                this.valuesRows[pages].push(this.dataProps.data.body[i])
                            }
                            else{
                                this.valuesRows[pages].push(this.dataProps.data.body[i])
                                pages++
                                rows = 0
                                this.valuesRows[pages] = []
                            }
                        }
                    }
                
                
            }
            catch(err) {
                alert(err)
            }
            pages = pages + 1
            this.numPages = pages
        },

        async Print(){
            this.loading = true
            var doc = new jsPDF({orientation: 'l', unit: 'pt', format: 'letter'})
            await doc.html(this.$refs.dataprint, {
                callback: function(doc){
                    doc.output()
                },
                x: 0,
                y: 0,
                margin: [0, 0, 0, 0],
                html2canvas:{ 
                    scale: 0.612,
                    //scale: (doc.internal.pageSize.width - 10 * 2) / this.$refs.dataprint.scrollWidth,
                    useCORS: true,
                    logging: false,
                    allowTaint: true
                },
            })
            
            
            //doc.deletePage(2)

            doc.autoPrint({variant: 'non-conform'});
            const blob = doc.output("bloburl")
            window.open(blob)

            this.loading = false
        },

        async Download(){
            this.loading = true
            var doc = new jsPDF({orientation: 'l', unit: 'pt', format: 'letter'})
            await doc.html(this.$refs.dataprint, {
                callback: function(doc){
                    doc.output()
                },
                x: 0,
                y: 0,
                margin: [0, 0, 0, 0],
                html2canvas:{ 
                    scale: 0.612,
                    //scale: (doc.internal.pageSize.width - 10 * 2) / this.$refs.dataprint.scrollWidth,
                    useCORS: true,
                    logging: false,
                    allowTaint: true
                },
            });
            
            //doc.deletePage(2)
        doc.save(this.dataProps.data.header.ID_Invoice+ "_"+this.dataProps.data.header.InvoiceDate.replaceAll("/","-") + "_"+this.dataProps.data.header.InvoiceName.replaceAll(/[:/<>*|/]/g, "").replaceAll('\\"','')+ ".pdf"); //Agregado por Juan 13082023

            this.loading = false
        },
        async sendMail() {
            try {
                this.mailLabel = null;
                var doc = new jsPDF({orientation: 'l', unit: 'pt', format: 'letter'})
                //console.log(doc);
                //console.log(this.$refs.dataprint);
                await doc.html(this.$refs.dataprint, {
                    callback: function(doc){
                        doc.output()
                    },
                    x: 0,
                    y: 0,
                    margin: [0, 0, 0, 0],
                    html2canvas:{ 
                        scale: 0.612,
                        //scale: (doc.internal.pageSize.width - 10 * 2) / this.$refs.dataprint.scrollWidth,
                        useCORS: true,
                        logging: false,
                        allowTaint: true
                    },
                });
                
                const body = {
                    dtefilebuffer: new Buffer.from(doc.output('arraybuffer')),
                    dtejson: this.dataProps.data.dteJson,
                    dtefilebufferfilename: this.dataProps.data.header.ID_Invoice+ "_"+this.dataProps.data.header.InvoiceDate.replaceAll("/","-") + "_"+this.dataProps.data.header.InvoiceName.replaceAll(/[:/<>*|/]/g, "").replaceAll('\\"','')+ ".pdf"
                };

                // console.log(body);
                this.$API.invoices.sendInvoiceMail(body);

                this.mailLabel = 'Se envió el correo correctamente';
                this.$emit('mailSent');
            } catch (error) {
                this.mailLabel = 'Ocurrió un error al enviar el correo';
                console.log(error);
            }
        },
        async downLoadJSON() {
            try {
                const aElement = document.createElement('a');
                aElement.setAttribute('download', this.dataProps.data.header.ID_Invoice+ "_"+this.dataProps.data.header.InvoiceDate.replaceAll("/","-") + "_"+this.dataProps.data.header.InvoiceName.replaceAll(/[:/<>*|/]/g, "").replaceAll('\\"','')+ ".pdf");
                const blob = new Blob([JSON.stringify(this.dataProps.data.dteJson)], { type: 'application/json' });
                const href = URL.createObjectURL(blob);
                aElement.href = href;
                aElement.download = this.dataProps.data.header.ID_Invoice+ "_"+this.dataProps.data.header.InvoiceDate.replaceAll("/","-") + "_"+this.dataProps.data.header.InvoiceName.replaceAll(/[:/<>*|/]/g, "").replaceAll('\\"','')+ ".json";
                aElement.click();
                URL.revokeObjectURL(href);
            } catch (error) {
                this.mailLabel = 'Ocurrió un error al enviar el correo';
                console.log(error);
            }
        }
    }
}
</script>

<style scoped>
    .u-row .u-col-100{
        width: 100% !important;
    }
    .btnclean{
        border-radius: 15px;
        border: 1px solid white;
  background-color: #F29D35 !important;
  color: white !important;
}
    .btnclose{
        border-radius: 15px;
        border: 1px solid white;
        background-color: rgb(255, 38, 38) !important;
        color: white !important;
       
    }

    * {
        font-family: Arial, Helvetica, sans-serif !important;
    }

 

</style>
